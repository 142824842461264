import { collection, collectionGroup, doc, documentId, endAt, getDoc, getDocs, onSnapshot, orderBy, query, startAt } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import firebaseRefList from "../../firebase/firebaseRefList";


export const useGetAllAssignedQuestions = (projectID) => {
    const [loading, setLoading] = useState(true);
    const [assignedQuestions, setAssignedQuestions] = useState(Object);

    useEffect(() => {
        if (!projectID) return;

        const getAsyncData = async () => {
            setLoading(true);
            const collectionRef = collection(db, "Projects/" + projectID + '/Steps/');

            getDocs(collectionRef).then(async (result) => {
                let localQuestionsSteps = {}
                for (let index = 0; index < result.docs.length; index++) {

                    const step = result.docs[index];

                    const questionsRef = collection(db, "Projects/" + projectID + '/Steps/' + step.id + "/AssignedQuestions/");

                    let questionsSnap = await getDocs(questionsRef);

                    let localQuestions = [];
                    for (let index = 0; index < questionsSnap.docs.length; index++) {
                        const question = questionsSnap.docs[index];
                        localQuestions.push({ id: question.id, ...question.data() })
                    }

                    if (localQuestions.length > 0) {
                        localQuestionsSteps[step.id] = {
                            name: step.data()?.name,
                            questions: localQuestions
                        };
                    }

                }

                setAssignedQuestions(localQuestionsSteps);
            }).catch((err) => {
                console.error("FB Error: ", err);
            }).finally(() => {
                setLoading(false);
            })
        }

        getAsyncData();
    }, [projectID])


    return assignedQuestions;
}