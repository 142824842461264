import { useMemo } from "react"

import { useAuth } from "../../contexts/AuthContext";
import { BOOK_MEETING_LABELS as BOOK_MEETING_LABELS_SWE } from "../../constants/lang/swe/signupLabels";
import { BOOK_MEETING_LABELS as BOOK_MEETING_LABELS_ENG } from "../../constants/lang/eng/signupLabels";

export const useGetSignUpTexts = () => {
    const { currentUser } = useAuth();

    const bookMeetingLabels = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return BOOK_MEETING_LABELS_SWE;
        }
        else {
            return BOOK_MEETING_LABELS_ENG;
        }
    }, [currentUser]);

    return bookMeetingLabels;
}