import { useState, useEffect, useMemo } from "react";
import { db } from '../../../firebase';
import Box from '@mui/material/Box';
import { useGetAllQuestions } from "../../../hooks/questionData/useGetAllQuestions";
import { useGetIdeaQuestions } from "../../../hooks/questionData/useGetIdeaQuestions";
import Card from "@mui/material/Card";
//import ReactHtmlParser from 'react-html-parser';
import parse from 'html-react-parser';

import { CircularProgress, Divider, Grid, Stack } from "@mui/material";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { BusinessIdeasQuestionsEstablishedEng, BusinessIdeasQuestionsEstablishedSwe, BusinessIdeasQuestionsNewCompEng, BusinessIdeasQuestionsNewCompSwe } from "../../../constants/ideasQuestions/businessIdeaQuestions";
import { useGetProjectEntryQuestions } from "../../../hooks/projectData/useGetProjectEntryQuestions";
import { WorkInGroupAnswers } from "../../../constants/labels/labels";
import { useGetAllAssignedQuestions } from "../../../hooks/projectData/useGetAllAssignedQuestions";
import { Masonry } from "@mui/lab";
import { useAuth } from "../../../contexts/AuthContext";


const ExitSummaryIBO = ({ projectData }) => {
    const { currentUser } = useAuth();
    const questions = useGetAllQuestions();
    const ideaQuestions = useGetIdeaQuestions();
    const assignedQuestions = useGetAllAssignedQuestions(projectData?.id);
    const { questionList, isLoading: loadingQuestions } = useGetProjectEntryQuestions(projectData?.id);

    //const [allQuestions] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const BusinessQuestions = useMemo(() => {
        if (projectData?.existingComp === 'no' || projectData?.existingComp === 'yes_new' || projectData?.existingComp === "Yes, in a newly formed company based on the idea" || projectData?.existingComp === 'No/Not yet') {
            if (currentUser?.userInfo?.prefLang === 'swe') {
                return BusinessIdeasQuestionsNewCompSwe;
            }
            else {
                return BusinessIdeasQuestionsNewCompEng;
            }
        }
        else if (projectData?.existingComp === 'yes_established' || projectData?.existingComp === 'Yes, within an established company') {
            if (currentUser?.userInfo?.prefLang === 'swe') {
                return BusinessIdeasQuestionsEstablishedSwe;
            }
            else {
                return BusinessIdeasQuestionsEstablishedEng;
            }
        }
        else {
            return {};
        }
    }, [projectData]);

    /*
    useEffect(() => {
        setIsLoading(true);
        const getData = async () => {
            await getDocs(query(collection(db, "Projects/" + projectID + "/Steps"), orderBy('order'))).then(async (documents) => {
                for (let index = 0; index < documents.docs.length; index++) {
                    const element = documents.docs[index];
                    if (element.id === 'a') {
                        await getDocs(collection(db, element.ref.path + "/Questions")).then((questionData) => {
                            const questionArray = {};
                            questionData.docs.forEach((questionSnap) => {
                                if (questionSnap.data().answer != null) {
                                    const questionInfo = questionSnap.data();
                                    if (ideaQuestions[questionSnap.id] != null) {
                                        questionInfo["text"] = ideaQuestions[questionSnap.id].text;
                                        questionInfo["order"] = ideaQuestions[questionSnap.id].order;
                                    }
                                    //console.log("ID: ", questionSnap.id);
                                    questionArray[questionSnap.id] = questionInfo
                                }
                            });

                            allQuestions[element.data().name] = questionArray;
                        });
                    }
                    else {
                        await getDocs(collection(db, element.ref.path + "/AssignedQuestions")).then((questionData) => {
                            const questionArray = {};
                            questionData.docs.forEach((questionSnap) => {
                                const question = questions.find(({ id }) => id === questionSnap.data().questionID);

                                const questionInfo = questionSnap.data();
                                if (question != null) {
                                    questionInfo["text"] = question.value;
                                    questionInfo["order"] = question.order;
                                    questionInfo["type"] = question.type;
                                    questionInfo["perks"] = question.perks;
                                }

                                questionArray[questionSnap.id] = questionInfo
                            });

                            allQuestions[element.data().name] = questionArray;
                        });
                    }
                }

                setIsLoading(false);
            });
        }

        getData();
    }, [projectID, questions]);*/


    function ascendingComparator(a, b) {
        if (b["order"] > a["order"]) {
            return -1;
        }
        if (b["order"] < a["order"]) {
            return 1;
        }
        return 0;
    }

    function showQuestionCard(step, question, key) {
        if (step === "Entry") {
            if (question.answer != null && question.answer !== "") {

                return (
                    <div key={key}>
                        <Card variant="outlined" sx={{ padding: 2 }}>
                            <span><strong>Question: </strong></span><br />
                            {question.text}<br />
                            <span><strong>Answer:</strong></span><br />
                            {question.answer}
                        </Card>
                    </div>
                );
            }
            else {
                return (
                    <div key={key}>
                        <Card variant="outlined" sx={{ padding: 2 }}>
                            <span><strong>Question: </strong></span><br />
                            {question.text}<br />
                            <span><strong>Answer:</strong></span><br />
                            No Answer!
                        </Card>
                    </div>
                );
            }
        }
        else {
            if (question.type === "EmbededLinkInput" || question.type === "RichTextInput" || question.type === "RichTextInput" || question.type === "miro" || question.perks === "Infinity") {
                return (<div key={key}></div>);
            }
            else {
                if (question.answer != null && question.answer !== "") {
                    return (
                        <div key={key}>
                            <Card variant="outlined" sx={{ padding: 2 }}>
                                <span><strong>Question: </strong></span><br />
                                {parse(question.text)}
                                <span><strong>Answer:</strong></span><br />
                                {question.answer}<br />
                            </Card>
                        </div>
                    );
                }
                else {
                    return (
                        <div key={key}>
                            <Card variant="outlined" sx={{ padding: 2 }}>
                                <span><strong>Question: </strong></span><br />
                                {parse(question.text)}
                                <span><strong>Answer:</strong></span><br />
                                <i>No Answer!</i>
                            </Card>
                        </div>
                    );
                }
            }
        }
    }

    const displayHomeworkQuestions = (questionData, index) => {
        let questionText = questions.find((obj) => obj.id === questionData?.questionID);

        if (questionText != null && questionText !== "") {
            if (questionText.type === "EmbededLinkInput" || questionText.type === "RichTextInput" || questionText.type === "RichTextInput" || questionText.type === "miro" || questionText.perks === "Infinity") {
                return (<div key={questionData?.id}></div>);
            }
            else {
                return (
                    <Box key={questionData.id} sx={{ width: '100%', paddingLeft: '10px', paddingTop: '5px' }}>
                        <Stack>
                            <Box fontSize={'0.8rem'} fontWeight={700}>
                                {parse(questionText.value)}
                            </Box>
                            <Box fontSize={'0.8rem'} fontStyle={'italic'}>
                                Answer:
                            </Box>
                            <Box fontSize={'0.9rem'}>
                                {questionData?.answer != null && questionData?.answer !== "" ? questionData?.answer : "No Answer!"}
                            </Box>
                        </Stack>
                        <Divider className='blue' sx={{ marginTop: '3px', marginBottom: '6px' }} />
                    </Box>
                )
            }
        }

    }

    const displayEntryQuestion = (questionData, answer) => {
        if ((questionData?.requiresKey != null && answer?.answerData?.answer === questionData?.requiresVal) || questionData?.requiresKey == null) {
            if (questionData.key === "workingInGroup") {
                return (
                    <Box key={questionData.key} sx={{ width: '100%', paddingLeft: '10px', paddingTop: '5px' }}>
                        <Stack spacing={1}>
                            <Box fontSize={'0.8rem'} fontWeight={700}>
                                {questionData?.orderLabel} {questionData?.text}
                            </Box>
                            <Box fontSize={'0.8rem'}>
                                {answer?.answerData?.answer != null && answer?.answerData?.answer !== "" ? WorkInGroupAnswers[answer?.answerData?.answer] : "No answer!"}
                            </Box>
                        </Stack>
                        <Divider className='blue' sx={{ marginTop: '3px', marginBottom: '6px' }} />
                    </Box>
                );
            }
            else {
                return (
                    <Box key={questionData.key} sx={{ width: '100%', paddingLeft: '10px', paddingTop: '5px' }}>
                        <Stack spacing={1}>
                            <Box fontSize={'0.8rem'} fontWeight={700}>
                                {questionData?.orderLabel} {questionData?.text}
                            </Box>
                            <Box fontSize={'0.9rem'}>
                                {answer?.answerData?.answer != null && answer?.answerData?.answer !== "" ? answer?.answerData?.answer : "No answer!"}
                            </Box>
                        </Stack>
                        <Divider className='blue' sx={{ marginTop: '3px', marginBottom: '6px' }} />
                    </Box>
                )
            }

        }
    }

    return (
        <div>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><span><CircularProgress /></span></div>
            ) : (
                <>
                    <Box sx={{ display: 'flex' }}>
                        <Grid container justifyContent={'center'} gap={1}>
                            <Grid item>
                                <Box sx={{ border: '1px solid #4eacc1', borderRadius: '4px', width: '650px' }}>
                                    <Box sx={{ fontSize: '1.1rem', fontWeight: 700, padding: '5px', paddingLeft: '15px' }}>
                                        Business Idea:
                                    </Box>
                                    <Divider className="blue" />
                                    {Object.keys(BusinessQuestions).map((key) => {
                                        let answerData = questionList.find((obj) => obj.id === key);
                                        return displayEntryQuestion(BusinessQuestions[key], answerData);
                                    })}
                                </Box>
                            </Grid>
                            {Object.keys(assignedQuestions).map((step) => {
                                return (
                                    <Grid item>
                                        <Box sx={{ border: '1px solid #4eacc1', borderRadius: '4px', width: '650px' }}>
                                            <Box sx={{ fontSize: '1.1rem', fontWeight: 700, padding: '5px', paddingLeft: '15px' }}>
                                                {assignedQuestions[step]?.name}
                                            </Box>
                                            <Divider className="blue" />
                                            {assignedQuestions[step]?.questions.sort((a, b) => a.orderBy - b.orderBy).map((questionData, index) => {
                                                return displayHomeworkQuestions(questionData, index + 1);
                                            })}
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>


                    {/*Object.keys(allQuestions).map((step) => {
                        return (
                            <div key={step} style={{ display: 'flex', justifyContent: 'center' }}>
                                {Object.keys(allQuestions[step]).length > 0 ? (
                                    <Box
                                        sx={{
                                            width: '44%',
                                            justifyContent: 'center',
                                        }}>
                                        <div style={{ paddingLeft: '15px', paddingBottom: '5px', paddingTop: '20px' }}>
                                            <h5>{step}:</h5>
                                        </div>
                                        <>
                                            {Object.keys(allQuestions[step]).sort((a, b) => ascendingComparator(allQuestions[step][a], allQuestions[step][b])).map((questionKey) => {
                                                return showQuestionCard(step, allQuestions[step][questionKey], questionKey);
                                            })}
                                        </>

                                    </Box>
                                ) : (
                                    ''
                                )}
                            </div>

                        );
                    })*/}

                </>
            )
            }

        </div >
    )
};

export default ExitSummaryIBO;