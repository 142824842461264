import { Box, Divider, IconButton, List, ListItem, ListItemText, Modal } from "@mui/material";
import { Fragment, useMemo, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from "../../../contexts/AuthContext";
import { QUESTION_INFO, QUESTION_INFO_TEAM } from "../../../constants/lang/eng/fields";
import UpdateIdeas from "../../UpdateData/UpdateIdeas";
import UpdateTeam from "../../UpdateData/UpdateTeam";
import { StyledListItemEntryStep } from "../../../helper/styledComponents";
import { WorkInGroupAnswers } from "../../../constants/labels/labels";

import AltRouteRoundedIcon from '@mui/icons-material/AltRouteRounded';
import { TeamsQuestions } from "../../../constants/ideasQuestions/teamsQuestions";
import { BusinessIdeasQuestionsEstablishedEng, BusinessIdeasQuestionsEstablishedSwe, BusinessIdeasQuestionsNewCompEng, BusinessIdeasQuestionsNewCompSwe } from "../../../constants/ideasQuestions/businessIdeaQuestions";
import UpdateIdeasV2 from "../../UpdateData/UpdateIdeasV2";

export const DisplayEntryQuestions = ({ projectID, questionList, projData, updateFrame }) => {
    const { currentUser } = useAuth();

    const BusinessQuestions = useMemo(() => {
        if (projData?.existingComp === 'no' || projData?.existingComp === 'yes_new' || projData?.existingComp === "Yes, in a newly formed company based on the idea" || projData?.existingComp === 'No/Not yet') {
            if (currentUser?.userInfo?.prefLang === 'swe') {
                return BusinessIdeasQuestionsNewCompSwe;
            }
            else {
                return BusinessIdeasQuestionsNewCompEng;
            }
        }
        else if (projData?.existingComp === 'yes_established' || projData?.existingComp === 'Yes, within an established company') {
            if (currentUser?.userInfo?.prefLang === 'swe') {
                return BusinessIdeasQuestionsEstablishedSwe;
            }
            else {
                return BusinessIdeasQuestionsEstablishedEng;
            }
        }
        else {
            return {};
        }
    }, [projData]);

    const [openModalIdea, setOpenModalIdea] = useState(false);
    const handleModalIdeaOpen = () => {
        setOpenModalIdea(true);
    }

    const handleModalIdeaClose = () => {
        updateFrame();
        setOpenModalIdea(false);
    }

    const [openModalTeam, setOpenModalTeam] = useState(false);
    const handleModalTeamOpen = () => {
        setOpenModalTeam(true);
    }
    const handleModalTeamClose = () => {
        updateFrame();
        setOpenModalTeam(false);
    }

    const displayTeams = () => {
        return false;
        let answer = questionList.find((val) => val.id === 'workingInGroup');
        console.log("Answer: ", answer)
        if (answer?.answerData?.answer === 'group') {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <Fragment>
            <Box sx={{ paddingBottom: '30px' }}>
                {currentUser.role >= 1 ? (
                    <span>
                        <strong>Business idea:</strong>

                        <IconButton
                            className="edit-icon-right"

                            onClick={handleModalIdeaOpen}>

                            <EditIcon style={{ width: "18px", height: "fit-content" }} />
                        </IconButton>
                    </span>
                ) : (
                    <span>
                        <strong>Business idea:</strong>
                    </span>
                )}

                {Object.keys(BusinessQuestions).map((question, questionIndex) => {
                    let answer = questionList.find((val) => val.id === question);
                    let questionLabel = BusinessQuestions[question]?.orderLabel + ' ' + BusinessQuestions[question]?.text;

                    if ((BusinessQuestions[question]?.requiresKey != null && answer?.answerData?.answer === BusinessQuestions[question]?.requiresVal) || BusinessQuestions[question]?.requiresKey == null) {
                        if (answer != null) {
                            if (question === "globalGoals") {
                                return (
                                    <List key={question} style={{ paddingLeft: '15px', width: '80%' }}>
                                        <StyledListItemEntryStep primary={questionLabel} secondary={""} />
                                        {answer.answerData?.answer != null ? String(answer.answerData?.answer).split(';').map((answer, index) => {
                                            return (
                                                <ListItem key={questionIndex + "" + index}>
                                                    <StyledListItemEntryStep primary={""} secondary={answer} />
                                                </ListItem>);
                                        }) : [""]}
                                        <Divider />
                                    </List>
                                )
                            }
                            else if (question === "workingInGroup") {
                                return (
                                    <List key={question} disablePadding style={{ width: '80%' }}>
                                        <ListItem key={question + "-questionText"}>
                                            <StyledListItemEntryStep primary={questionLabel} secondary={answer.answerData?.answer != null && answer.answerData?.answer !== "" ? WorkInGroupAnswers[answer.answerData?.answer] : "No answer!"} />
                                        </ListItem>
                                        <Divider />
                                    </List>
                                );
                            }
                            else {
                                return (
                                    <List key={question} disablePadding style={{ width: '80%' }}>
                                        <ListItem key={question + "-questionText"}>
                                            {question === 'workingInGroup' && currentUser.role >= 2 && (
                                                <AltRouteRoundedIcon style={{ marginLeft: '-40px', marginRight: '15px', color: 'green' }} />
                                            )}
                                            <StyledListItemEntryStep primary={questionLabel} secondary={answer.answerData?.answer != null && answer.answerData?.answer !== "" ? answer.answerData?.answer : "No answer!"} />
                                        </ListItem>
                                        <Divider />
                                    </List>)
                            }
                        }
                        else {
                            return (
                                <List key={question} disablePadding style={{ width: '80%' }}>
                                    <ListItem key={question + "-questionText"}>
                                        {question === 'workingInGroup' && currentUser.role >= 2 && (
                                            <AltRouteRoundedIcon style={{ marginLeft: '-40px', marginRight: '15px', color: 'green' }} />
                                        )}
                                        <StyledListItemEntryStep primary={questionLabel} secondary={"No answer!"} />
                                    </ListItem>
                                    <Divider />
                                </List>)
                        }
                    }
                })}
            </Box>
            <Box>
                {displayTeams() ? (
                    <>
                        {currentUser.role >= 1 ? (
                            <span>
                                <strong>Team:</strong>

                                <IconButton
                                    className="edit-icon-right"
                                    onClick={handleModalIdeaOpen}>

                                    <EditIcon style={{ width: "18px", height: "fit-content" }} />
                                </IconButton>
                            </span>
                        ) : (
                            <span>
                                <strong>Team:</strong>
                            </span>
                        )}

                        {Object.keys(TeamsQuestions).map((question, questionIndex) => {
                            let answer = questionList.find((val) => val.id === question);

                            if (answer != null) {
                                if (question === "globalGoals") {
                                    return (
                                        <List key={question} style={{ paddingLeft: '15px', width: '80%' }}>
                                            <StyledListItemEntryStep primary={TeamsQuestions[question].text} secondary={""} />
                                            {answer.answerData?.answer != null ? String(answer.answerData?.answer).split(';').map((answer, index) => {
                                                return (
                                                    <ListItem key={questionIndex + "" + index}>
                                                        <StyledListItemEntryStep primary={""} secondary={answer} />
                                                    </ListItem>);
                                            }) : [""]}
                                            <Divider />
                                        </List>
                                    )
                                }
                                else {
                                    return (
                                        <List key={question} disablePadding style={{ width: '80%' }}>
                                            <ListItem key={question + "-questionText"}>
                                                <StyledListItemEntryStep primary={TeamsQuestions[question].text} secondary={answer.answerData?.answer != null ? answer.answerData?.answer : "No answer!"} />
                                            </ListItem>
                                            <Divider />
                                        </List>
                                    );
                                }
                            }
                            else {
                                return (
                                    <List key={question} disablePadding style={{ width: '80%' }}>

                                        <ListItem key={question + "-questionText"}>
                                            <StyledListItemEntryStep primary={TeamsQuestions[question].text} secondary={"No answer!"} />
                                        </ListItem>
                                        <Divider />
                                    </List>
                                );
                            }
                        })}

                    </>
                ) : ("")}
            </Box>
            <Modal
                open={openModalIdea}
                onClose={handleModalIdeaClose}
            >
                <UpdateIdeasV2 projectID={projectID} ideasData={questionList} projData={projData} closeModal={handleModalIdeaClose} />
            </Modal>

            <Modal
                open={openModalTeam}
                onClose={handleModalTeamClose}
            >
                <UpdateTeam projectID={projectID} teamData={questionList} closeModal={handleModalTeamClose} />
            </Modal>
        </Fragment>
    )
};