import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, LinearProgress, Radio, RadioGroup, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { BusinessIdeasQuestions, BusinessIdeasQuestionsEstablishedEng, BusinessIdeasQuestionsEstablishedSwe, BusinessIdeasQuestionsNewCompEng, BusinessIdeasQuestionsNewCompSwe } from "../../../constants/ideasQuestions/businessIdeaQuestions";
import { useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useGetUserProjectId } from "../../../hooks/userData/useGetUserProjectId";
import { entryStepQuestions, entryStepQuestions_save } from "../../../firebase/cloudFunctions";

import textBubble from '../../../assets/images/tip-bubble.png'
import { ErrorAlert } from "../../../helper/errorAlert";
import { useAuth } from "../../../contexts/AuthContext";

export const IdeasStep_V2 = ({ handleChange, values, compInfo }) => {
    const navigate = useNavigate();
    const analytics = getAnalytics();
    const { currentUser } = useAuth()
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [liveSaving, setLiveSaving] = useState(false);
    const [saveTrigger, setSaveTrigger] = useState('')
    const [autoSave, setAutoSave] = useState('');
    const { projectIDs, loadingProjID } = useGetUserProjectId();
    const [update, setUpdate] = useState(false);

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const answerLabel = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return "Svar"
        }
        else {
            return "Answer"
        }
    }, [currentUser])

    const existingCompVal = useMemo(() => {
        return compInfo?.existingComp
    }, [compInfo]);

    const filteredQuestions = useMemo(() => {
        let localList = [];

        if (existingCompVal === 'yes_established') {
            if (currentUser?.userInfo?.prefLang === 'swe') {
                Object.keys(BusinessIdeasQuestionsEstablishedSwe).forEach((key) => {
                    if (BusinessIdeasQuestionsEstablishedSwe[key] != null && BusinessIdeasQuestionsEstablishedSwe[key]?.disabled !== true) {
                        localList.push(BusinessIdeasQuestionsEstablishedSwe[key]);
                    }
                });
            }
            else {
                Object.keys(BusinessIdeasQuestionsEstablishedEng).forEach((key) => {
                    if (BusinessIdeasQuestionsEstablishedEng[key] != null && BusinessIdeasQuestionsEstablishedEng[key]?.disabled !== true) {
                        localList.push(BusinessIdeasQuestionsEstablishedEng[key]);
                    }
                });
            }
        }
        else {
            if (currentUser?.userInfo?.prefLang === 'swe') {
                Object.keys(BusinessIdeasQuestionsNewCompSwe).forEach((key) => {
                    if (BusinessIdeasQuestionsNewCompSwe[key] != null && BusinessIdeasQuestionsNewCompSwe[key]?.disabled !== true) {
                        localList.push(BusinessIdeasQuestionsNewCompSwe[key]);
                    }
                });
            }
            else {
                Object.keys(BusinessIdeasQuestionsNewCompEng).forEach((key) => {
                    if (BusinessIdeasQuestionsNewCompEng[key] != null && BusinessIdeasQuestionsNewCompEng[key]?.disabled !== true) {
                        localList.push(BusinessIdeasQuestionsNewCompEng[key]);
                    }
                });
            }

        }

        return localList.sort((a, b) => a?.order - b?.order);
    }, [existingCompVal, BusinessIdeasQuestionsEstablishedSwe, BusinessIdeasQuestionsEstablishedEng, BusinessIdeasQuestionsNewCompSwe, BusinessIdeasQuestionsNewCompEng])

    const isGeneralInvalid = useMemo(() => {
        if (values != null) {
            for (let index = 0; index < filteredQuestions.length; index++) {
                const question = filteredQuestions[index];

                if ((question?.requiresKey != null && values[question?.requiresKey] === question?.requiresVal) || question?.requiresKey == null) {
                    if (question.required === true && (values[question?.key] == null || values[question?.key] === "")) {
                        return true;
                    }
                }
            }

            return false;
        }
        else {
            return true;
        }
    }, [values, filteredQuestions, update])

    useEffect(() => {
        if (saveTrigger !== '') {
            const delayDebounceFn = setTimeout(() => {
                saveProgress();
            }, 3000)

            return () => {
                clearTimeout(delayDebounceFn);
            }
        }
    }, [saveTrigger]);

    /**
     * 
     * @param {boolean} ignoreEmpty 
     * @returns 
     */
    function collectDataSaveProgress(ignoreEmpty) {
        const dataToSend = [];

        if (values != null) {
            for (let index = 0; index < filteredQuestions.length; index++) {
                const question = filteredQuestions[index];

                if (ignoreEmpty === true) {
                    if (values[question?.key] != null || values[question?.key] !== "") {
                        dataToSend.push({ title: question?.key, answer: values[question?.key] });
                    }
                }
                else {
                    dataToSend.push({ title: question?.key, answer: values[question?.key] });
                }

            }
        }

        return dataToSend;
    }

    const handleSubmit = (e) => {
        e.preventDefault()


        if (isGeneralInvalid === false) {
            setIsLoading(true);
            const saveData = collectDataSaveProgress(false);

            entryStepQuestions({
                projectID: projectIDs[0],
                entryQuestions: saveData,
            }).then(() => {
                logEvent(analytics, 'completed_signup_step', {
                    step: 4,
                    invited: false,
                });
                navigate('/set-up-account#firstmeet')
            }).catch((error) => {
                console.error("Error", error);
                setErrorInfo(error)
                setErrorMsg('Something went wrong! Could not save data.');
                setError(true)
            }).finally(() => {
                setIsLoading(false);
            })
        }
        console.log("Submit")
    }

    const handleChangeLocal = (e) => {
        handleChange(e);
        setSaveTrigger(e.target.value);
        setUpdate(!update);
    };

    const saveProgress = () => {
        const saveData = collectDataSaveProgress(false);
        if (saveData.length > 0) {
            setIsSaveLoading(true);
            // call the cloud function. 

            console.log("Saving! ", saveData)

            entryStepQuestions_save({
                projectID: projectIDs[0],
                entryQuestions: saveData,
            }).then(() => {
                setAutoSave(new Date(Date.now()).toLocaleString())
            }).catch((error) => {
                console.error("Error", error)
                setErrorInfo(error)
                setErrorMsg('Something went wrong! Could not save data.');
                setError(true)
            }).finally(() => {
                setIsSaveLoading(false);
            });
        }
    };

    return (
        <>
            <div style={{ padding: '10px' }}>
                <form onSubmit={handleSubmit} id="project-form" >
                    <Grid container gap={2} justifyContent="center">
                        {filteredQuestions.map((item) => {
                            if ((item?.requiresKey != null && values[item?.requiresKey] === item?.requiresVal) || item?.requiresKey == null) {
                                if (item?.type === 'radioGroup') {
                                    const options = item?.options;
                                    return (
                                        <Grid key={item?.key} item xs={12} sm={12} md={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                                            <FormControl sx={{ width: '97%', gap: '4px' }} required={item?.required}>
                                                <FormLabel sx={{ marginX: '5px' }}>{item?.orderLabel + " " + item?.text}</FormLabel>
                                                <RadioGroup
                                                    sx={{ height: '150px' }}
                                                    name={item?.key}
                                                    value={values[item?.key] || null}
                                                    onChange={handleChangeLocal}
                                                >
                                                    {options.map((option) => (
                                                        <FormControlLabel
                                                            key={option?.value}
                                                            value={option?.value}
                                                            control={<Radio />}
                                                            label={option?.label}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    );
                                }
                                else {
                                    return (
                                        <Grid key={item?.key} item xs={12} sm={12} md={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                                            <FormControl sx={{ width: '97%', gap: '4px' }} key={item?.key} component="fieldset" required={item?.required}>
                                                <FormLabel sx={{ marginX: '5px' }}>{item?.orderLabel + " " + item?.text}</FormLabel>
                                                <TextField
                                                    sx={{ height: '150px' }}
                                                    label={answerLabel}
                                                    multiline
                                                    name={item?.key}
                                                    rows={4}
                                                    onChange={handleChangeLocal}
                                                    value={values[item?.key] || ""}
                                                />
                                            </FormControl>
                                        </Grid>
                                    );
                                }
                            }
                        })}
                    </Grid>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {isLoading || isSaveLoading ? (
                            <Box sx={{ maxWidth: '310px', minWidth: '260px' }}>
                                <LinearProgress color='info' />
                            </Box>
                        ) : (
                            <Box sx={{ marginBottom: "4px" }}>

                            </Box>
                        )}
                    </div>

                    <div className="backConfirm" style={{ paddingBottom: '10px' }}>
                        <Button variant="contained" onClick={saveProgress} disabled={isSaveLoading} style={{ marginRight: 40 }}>
                            Save
                        </Button>
                        <Button
                            disabled={isSaveLoading || isGeneralInvalid}
                            variant="contained"
                            type="submit"
                        >
                            Save & Continue
                        </Button>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px', color: '#28a745' }}>
                        <i>{autoSave !== '' ? ('Last save: ' + autoSave) : ('')}</i>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}>
                        <img src={textBubble} alt="Tip! You can save and resume your sign up at a later time." height={'190px'} />
                    </div>
                </form>
            </div>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </>
    )
};