import { Box, CircularProgress, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { GRADING_COLUMNS_TAGS, GRADING_TABLE_HEADERS } from "../../../constants/grading"

import '../css/Grading.css'
import { useEffect, useState } from "react"
import { SystemDefaultMatrixRow } from "./SystemDefaultMatrixRow"
import { useGetSystemDefaultMilestones } from "../../../hooks/grading/useGetSystemDefaultMilestones"
import { SystemDefaultMilestonesDialog } from "./SystemDefaultMilestonesDialog"
import { addSystemDefaultMilestoneDataCellFunc } from "../../../firebase/cloudFunctions"


export const SystemDefaultMatrix = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [openEditCell, setOpenEditCell] = useState(false);
    const [selectedCell, setSelectedCell] = useState({
        level: null,
        tag: null,
        levelData: null
    });

    const [levelData, setLevelData] = useState({
        'CR': {},
        'TeamR': {},
        'BR': {},
        'SR': {},
        'FR': {},
        'TechR': {},
        'IPRR': {},
    });

    const { loading: loadingCR, levels: levelsCR } = useGetSystemDefaultMilestones(GRADING_COLUMNS_TAGS.CRTag);
    const { loading: loadingTeamR, levels: levelsTeamR } = useGetSystemDefaultMilestones(GRADING_COLUMNS_TAGS.TeamRTag);
    const { loading: loadingBR, levels: levelsBR } = useGetSystemDefaultMilestones(GRADING_COLUMNS_TAGS.BRTag);
    const { loading: loadingSR, levels: levelsSR } = useGetSystemDefaultMilestones(GRADING_COLUMNS_TAGS.SRTag);
    const { loading: loadingFR, levels: levelsFR } = useGetSystemDefaultMilestones(GRADING_COLUMNS_TAGS.FRTag);
    const { loading: loadingTechR, levels: levelsTechR } = useGetSystemDefaultMilestones(GRADING_COLUMNS_TAGS.TechRTag);
    const { loading: loadingIPRR, levels: levelsIPRR } = useGetSystemDefaultMilestones(GRADING_COLUMNS_TAGS.IPRR);

    const [currentTag, setCurrentTag] = useState('');
    const [currentLevel, setCurrentLevel] = useState(0);
    const [finalCell, setFinalCell] = useState(false);

    const [hasStartVal, setHasStartVal] = useState(false);
    const [hasTargetVal, setHasTargetVal] = useState(false);
    const [hasVerfifiedMilestones, setHasVerfifiedMilestones] = useState(false);
    const [startVal, setStartVal] = useState(Object);
    const [currentVal, setCurrentVal] = useState(Object);
    const [endVal, setEndVal] = useState(Object);
    const [update, setUpdate] = useState(false);
    const [verifyMilestones, setVerifyMilestones] = useState(false);

    const [isEditStart, setIsEditStart] = useState(false);
    const [isEditTarget, setIsEditTarget] = useState(false);

    const required = [
        "CR",
        "TeamR",
        "BR",
        "SR",
        "FR",
    ]


    const levels = [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
    ]

    useEffect(() => {
        if (loadingCR === false) {
            levelData[GRADING_COLUMNS_TAGS.CRTag] = levelsCR;
        }
    }, [loadingCR, levelsCR])

    useEffect(() => {
        if (loadingTeamR === false) {
            levelData[GRADING_COLUMNS_TAGS.TeamRTag] = levelsTeamR;
        }
    }, [loadingTeamR, levelsTeamR])

    useEffect(() => {
        if (loadingBR === false) {
            levelData[GRADING_COLUMNS_TAGS.BRTag] = levelsBR;
        }
    }, [loadingBR, levelsBR])

    useEffect(() => {
        if (loadingSR === false) {
            levelData[GRADING_COLUMNS_TAGS.SRTag] = levelsSR;
        }
    }, [loadingSR, levelsSR])

    useEffect(() => {
        if (loadingFR === false) {
            levelData[GRADING_COLUMNS_TAGS.FRTag] = levelsFR;
        }
    }, [loadingFR, levelsFR])

    useEffect(() => {
        if (loadingTechR === false) {
            levelData[GRADING_COLUMNS_TAGS.TechRTag] = levelsTechR;
        }
    }, [loadingTechR, levelsTechR])

    useEffect(() => {
        if (loadingIPRR === false) {
            levelData[GRADING_COLUMNS_TAGS.IPRR] = levelsIPRR;
        }
    }, [loadingIPRR, levelsIPRR])

    /*
    useEffect(() => {
        if (loading === false) {
            setCurrentVal(currentActive);
        }
    }, [loading]);*/

    const handleOpenCellDialog = (level, tag) => {
        setVerifyMilestones(false);
        let localLevel = null;

        if (levelData[tag] != null && levelData[tag][level] != null) {
            localLevel = levelData[tag][level];
        }
        setSelectedCell({
            level: level,
            tag: tag,
            levelData: localLevel,
        });

        setOpenEditCell(true);
    }

    const handleCloseCellDialog = () => {
        setOpenEditCell(false);

        setSelectedCell({
            level: null,
            tag: null,
            levelData: null,
        });
    }


    const handleOpenVerifyMilestones = () => {
        setVerifyMilestones(true);
        setFinalCell(false);
        let tag = 'CR';
        let level = startVal[tag];
        let localLevel = null;
        if (levelData[tag] != null && levelData[tag][level] != null) {
            localLevel = levelData[tag][level];
        }
        setCurrentTag(tag);
        setCurrentLevel(level);
        setSelectedCell({
            level: level,
            tag: tag,
            levelData: localLevel,
        });
        setOpenEditCell(true);
    }

    const handleSaveMilestoneData = (tag, level, milestones) => {
        let localLevelData = {};
        if (levelData[tag] != null && levelData[tag][level] != null)
            localLevelData = levelData[tag][level];

        console.log("Save milestonedata: ", localLevelData, milestones)

        let milestonesText = [];

        for (let index = 0; index < milestones.length; index++) {
            const milestone = milestones[index];

            milestonesText.push(milestone.text);
        }

        localLevelData['Milestones'] = milestonesText;

        levelData[tag][level] = localLevelData;

        addSystemDefaultMilestoneDataCellFunc({
            columnTag: tag,
            level: level,
            levelData: localLevelData,
        }).then(() => {
            console.log("Saved: ", tag + " - " + level);
        }).catch((error) => {
            console.error("Error saving: ", tag + " - " + level, "Error: ", error);
        });
    }

    const nextTag = (currentTag) => {
        switch (currentTag) {
            case GRADING_COLUMNS_TAGS.CRTag:
                return GRADING_COLUMNS_TAGS.TeamRTag;
            case GRADING_COLUMNS_TAGS.TeamRTag:
                return GRADING_COLUMNS_TAGS.BRTag;
            case GRADING_COLUMNS_TAGS.BRTag:
                return GRADING_COLUMNS_TAGS.SRTag;
            case GRADING_COLUMNS_TAGS.SRTag:
                return GRADING_COLUMNS_TAGS.FRTag;
            case GRADING_COLUMNS_TAGS.FRTag:
                return GRADING_COLUMNS_TAGS.TechRTag;
            case GRADING_COLUMNS_TAGS.TechRTag:
                return GRADING_COLUMNS_TAGS.IPRR;
            case GRADING_COLUMNS_TAGS.IPRR:
                return null;
            default:
                return null;
        }
    }

    return (
        <>
            <Table className="grade-table" id="table">
                <TableBody>
                    <TableHead className="grade-table">
                        <TableRow>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS['Level']}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.CRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.TeamRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.BRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.SRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.FRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.TechRTag]} (OPTIONAL)
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.IPRR]} (OPTIONAL)
                            </TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {levels.map((level) => {
                            return (
                                <SystemDefaultMatrixRow
                                    key={'grading-' + level}
                                    level={level}
                                    openCellDialog={handleOpenCellDialog}
                                    selectedCell={selectedCell}
                                />
                            )
                        })}
                    </TableBody>

                </TableBody>
            </Table>

            <SystemDefaultMilestonesDialog 
                open={openEditCell}
                handleClose={handleCloseCellDialog}
                cell={selectedCell}
                update={update}
                handleSaveMilestoneData={handleSaveMilestoneData}
            />
        </>

    )
}