export const BusinessIdeasQuestionsEstablishedEng = {
    already: {
        key: 'already',
        type: 'textField',
        order: 0,
        orderLabel: '1.',
        required: true,
        text: "Briefly describe your idea/business",
    },
    unique: {
        key: 'unique',
        type: 'textField',
        order: 1,
        orderLabel: '2.',
        required: true,
        text: "What do you think works well in the company today? (Max 3 sentences)",
    },
    challenge: {
        key: 'challenge',
        type: 'textField',
        order: 2,
        orderLabel: '3.',
        required: true,
        text: "What is your companys biggest challenge today?"
    },
    goals: {
        key: 'goals',
        type: 'textField',
        order: 3,
        orderLabel: '4.',
        required: true,
        text: "What is your personal goal with the company in the short term (within 1 year), as well as in the long term (3 years or more)? (Max 3 sentences)"
    },
    foodConnection: {
        key: 'foodConnection',
        type: 'radioGroup',
        order: 4,
        orderLabel: '5.',
        required: true,
        text: "Is your idea/business food, food-tech or agri-tech-related?",
        options: [
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
        ],
    },
}

export const BusinessIdeasQuestionsEstablishedSwe = {
    already: {
        key: 'already',
        type: 'textField',
        order: 0,
        orderLabel: '1.',
        required: true,
        text: "Beskriv kortfattat din idé/verksamhet",
    },
    unique: {
        key: 'unique',
        type: 'textField',
        order: 1,
        orderLabel: '2.',
        required: true,
        text: "Vad uppfattar du/ni fungerar bra i företaget idag? (Max 3 meningar)",
    },
    challenge: {
        key: 'challenge',
        type: 'textField',
        order: 2,
        orderLabel: '3.',
        required: true,
        text: "Vad är er största utmaning idag? (Max 3 meningar)"
    },
    goals: {
        key: 'goals',
        type: 'textField',
        order: 3,
        orderLabel: '4.',
        required: true,
        text: "Vad är ditt personliga mål med företaget på kort sikt (inom 1 år), samt på lång sikt (3 år eller mer)? (Max 3 meningar)"
    },
    foodConnection: {
        key: 'foodConnection',
        type: 'radioGroup',
        order: 4,
        orderLabel: '5.',
        required: true,
        text: "Har din idé/ditt företag en koppling till livsmedel, food-tech eller agri-tech?",
        options: [
            { value: "yes", label: "Ja" },
            { value: "no", label: "Nej" },
        ],
    },
}

export const BusinessIdeasQuestionsNewCompEng = {
    already: {
        key: 'already',
        type: 'textField',
        order: 0,
        orderLabel: '1.',
        required: true,
        text: "Briefly describe your idea/business",
    },
    unique: {
        key: 'unique',
        type: 'textField',
        order: 1,
        orderLabel: '2.',
        required: true,
        text: "In what way is your service/product/idea better than those on the market today?",
    },
    competitors: {
        key: 'competitors',
        type: 'textField',
        order: 2,
        orderLabel: '3.',
        required: true,
        text: "If you launched your product/service today, who would be your three biggest competitors?"
    },
    customers: {
        key: 'customers',
        type: 'textField',
        order: 3,
        orderLabel: '4.',
        required: true,
        text: "Who is your customer? Who will pay for your product/service?"
    },
    goals: {
        key: 'goals',
        type: 'textField',
        order: 4,
        orderLabel: '5.',
        required: true,
        text: "What is your goal with your idea/business? Why do you want to do this?"
    },
    workingInGroup: {
        key: 'workingInGroup',
        type: 'radioGroup',
        order: 5,
        orderLabel: '6a.',
        required: true,
        text: "Are you developing this idea alone or together with others?",
        options: [
            { value: "alone", label: "Alone" },
            { value: "group", label: "Group" },
        ],
    },
    aloneORteam: {
        key: 'aloneORteam', 
        type: 'textField',
        required: true,
        requiresKey: 'workingInGroup',
        requiresVal: 'group',
        order: 6,
        orderLabel: '6b.',
        text: "Which key skills or experiences do you have within your team at present?"
    },
    competences: {
        key: 'competences', 
        type: 'textField',
        required: true,
        requiresKey: 'workingInGroup',
        requiresVal: 'group',
        order: 7,
        orderLabel: '6c.',
        text: "Which key skills or experiences do you see a need for in the future?"
    },
    reasonsSuccess: {
        key: 'reasonsSuccess', 
        type: 'textField',
        required: true,
        order: 8,
        orderLabel: '7.',
        text: "List the top 3 reasons why your idea/business will succeed (if possible in the form of your top 3 key immaterial assets)"
    },
    reasonsFail: {
        key: 'reasonsFail', 
        type: 'textField',
        required: true,
        order: 9,
        orderLabel: '8.',
        text: "List the top 3 reasons why your idea/business might fail"
    },
    nextStep: {
        key: 'nextStep',
        type: 'textField',
        order: 10,
        orderLabel: '9.',
        required: true,
        text: "In what way will your key assets/reason for success give you the necessary time advantage against competing solutions, and what is the plan to take benefit of this advantage? "
    },
    motivate: {
        key: 'motivate',
        type: 'textField',
        order: 11,
        orderLabel: '10.',
        required: true,
        text: "In what way can your idea/company contribute to a more sustainable society? What competitive advantages can your idea/company develop with the help of a sustainable strategy?"
    },
    foodConnection: {
        key: 'foodConnection',
        type: 'radioGroup',
        order: 12,
        orderLabel: '11.',
        required: true,
        text: "Is your idea/business food, food-tech or agri-tech-related?",
        options: [
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
        ],
    },
}

export const BusinessIdeasQuestionsNewCompSwe = {
    already: {
        key: 'already',
        type: 'textField',
        order: 0,
        orderLabel: '1.',
        required: true,
        text: "Beskriv kortfattat din idé/verksamhet",
    },
    unique: {
        key: 'unique',
        type: 'textField',
        order: 1,
        orderLabel: '2.',
        required: true,
        text: "Vad har du/din idé som ingen annan har?",
    },
    competitors: {
        key: 'competitors',
        type: 'textField',
        order: 2,
        orderLabel: '3.',
        required: true,
        text: "Om du lanserade din produkt/tjänst idag, vilka hade varit dina 3 största konkurrenter?"
    },
    customers: {
        key: 'customers',
        type: 'textField',
        order: 3,
        orderLabel: '4.',
        required: true,
        text: "Vem är din kund? Vem kommer betala för din produkt/tjänst?"
    },
    goals: {
        key: 'goals',
        type: 'textField',
        order: 4,
        orderLabel: '5.',
        required: true,
        text: "Vad är ditt mål med din idé/ditt företag? Varför vill du göra det här?"
    },
    workingInGroup: {
        key: 'workingInGroup',
        type: 'radioGroup',
        order: 5,
        orderLabel: '6a.',
        required: true,
        text: "Arbetar du idag ensam med detta eller har du ett team/samarbetspartners?",
        options: [
            { value: "alone", label: "Ensam" },
            { value: "group", label: "Grupp" },
        ],
    },
    aloneORteam: {
        key: 'aloneORteam', 
        type: 'textField',
        required: true,
        requiresKey: 'workingInGroup',
        requiresVal: 'group',
        order: 6,
        orderLabel: '6b.',
        text: "Vilka kompetenser och erfarenheter finns i teamet idag?"
    },
    competences: {
        key: 'competences', 
        type: 'textField',
        required: true,
        requiresKey: 'workingInGroup',
        requiresVal: 'group',
        order: 7,
        orderLabel: '6c.',
        text: "Vilka kompetenser eller erfarenheter ser du ett behov av i framtiden?"
    },
    reasonsSuccess: {
        key: 'reasonsSuccess', 
        type: 'textField',
        required: true,
        order: 8,
        orderLabel: '7.',
        text: "Ge de 3 främsta skälen till att det här kommer lyckas (om möjligt formulerat som verksamhetens 3 främsta nyckeltillgångar)"
    },
    reasonsFail: {
        key: 'reasonsFail', 
        type: 'textField',
        required: true,
        order: 9,
        orderLabel: '8.',
        text: "Ge de 3 främsta skälen till att det här kan misslyckas"
    },
    nextStep: {
        key: 'nextStep',
        type: 'textField',
        order: 10,
        orderLabel: '9.',
        required: true,
        text: "På vilket sätt kommer verksamhetens skäl till framgång/nyckeltillgångar ge ett nödvändigt tidsförsprång relativt konkurrerande lösningar? Hur ser planen ut för att genomföra detta? "
    },
    motivate: {
        key: 'motivate',
        type: 'textField',
        order: 11,
        orderLabel: '10.',
        required: true,
        text: "På vilket sätt kan din idé/ditt företag bidra till ett mer hållbart samhälle? Vilka konkurrensfördelar kan din idé/ditt företag utveckla med hjälp av en hållbarhetstrategi?"
    },
    foodConnection: {
        key: 'foodConnection',
        type: 'radioGroup',
        order: 12,
        orderLabel: '11.',
        required: true,
        text: "Har din idé/ditt företag en koppling till livsmedel, food-tech eller agri-tech?",
        options: [
            { value: "yes", label: "Ja" },
            { value: "no", label: "Nej" },
        ],
    },
}

export const BusinessIdeasQuestions = {
    ideas: {
        key: 'ideas',
        type: 'textField',
        order: 1,
        orderLabel: '1.',
        required: true,
        disabled: true,
        text: "What need does your business idea meet? How did you discover that need?"
    },
    already: {
        key: 'already',
        type: 'textField',
        order: 2,
        orderLabel: '1.',

        useInNewComp: true,
        useInEstablishedComp: true,
        required: true,
        text: "Briefly describe your idea/business",
    },
    unique: {
        key: 'unique',
        type: 'textField',
        order: 3,
        orderLabel: '3.',
        required: true,
        useInNewComp: true,
        useInEstablishedComp: true,
        text: "In what way is your service / product / idea better than those on the market today?",
        textNewComp: "In what way is your service/product/idea better than those on the market today?",
        textEstablishedComp: "What do you think works well in the company today? (Max 3 sentences)",
    },
    motivate: {
        key: 'motivate',
        type: 'textField',
        order: 4,
        orderLabel: '4.',
        required: false,
        text: "In what way does your project contribute to the sustainable development of society?"
    },
    globalGoals: {
        key: 'globalGoals',
        type: 'multiCheckbox',
        order: 5,
        orderLabel: '4b.',
        required: false,
        text: "Which of the Global goals for sustainable development does your idea contribute to, if any?",
        options: [
            { key: "poverty", value: "1 - No poverty", label: "1 - No poverty" },
            { key: "hunger", value: "2 - Zero hunger", label: "2 - Zero hunger" },
            { key: "goodHealth", value: "3 - Good health and well-being", label: "3 - Good health and well-being" },
            { key: "education", value: "4 - Quality education", label: "4 - Quality education" },
            { key: "equality", value: "5 - Gender equality", label: "5 - Gender equality" },
            { key: "sanitation", value: "6 - Clean water and sanitation", label: "6 - Clean water and sanitation" },
            { key: "energy", value: "7 - Affordable and clean energy", label: "7 - Affordable and clean energy" },
            { key: "economicGrowth", value: "8 - Decent work and economic growth", label: "8 - Decent work and economic growth" },
            { key: "industry", value: "9 - Industry, innovation and infrastructure", label: "9 - Industry, innovation and infrastructure" },
            { key: "inequalities", value: "10 - Reduced inequalities", label: "10 - Reduced inequalities" },
            { key: "cities", value: "11 - Sustainable cities and communities", label: "11 - Sustainable cities and communities" },
            { key: "consumption", value: "12 - Responsible consumption and production", label: "12 - Responsible consumption and production" },
            { key: "climate", value: "13 - Climate action", label: "13 - Climate action" },
            { key: "lifeWater", value: "14 - Life below water", label: "14 - Life below water" },
            { key: "lifeLand", value: "15 - Life on land", label: "15 - Life on land" },
            { key: "peace", value: "16 - Peace, justice and strong institutions", label: "16 - Peace, justice and strong institutions" },
            { key: "partnerships", value: "17 - Partnerships for the goals", label: "17 - Partnerships for the goals" },
        ]
    },
    customers: {
        key: 'customers',
        type: 'textField',
        order: 6,
        orderLabel: '5.',
        required: true,
        text: "Describe the target group for your product / service, who is your customer?"
    },
    demand: {
        key: 'demand',
        type: 'textField',
        order: 6,
        orderLabel: '6.',
        required: true,
        text: "Have you tested your business idea?"
    },
    competitors: {
        key: 'competitors',
        type: 'textField',
        order: 6,
        orderLabel: '7.',
        required: true,
        text: "If you launched your product / service today, who would be your three biggest competitors?"
    },
    timeANDresources: {
        key: 'timeANDresources',
        type: 'textField',
        order: 7,
        orderLabel: '8.',
        required: true,
        text: "How much time (in total for the whole team) can you spend on the project each week? For example: 10 hours a week."
    },
    challenge: {
        key: 'challenge',
        type: 'textField',
        order: 8,
        orderLabel: '9.',
        required: true,
        text: "What are you working on right now?"
    },
    nextStep: {
        key: 'nextStep',
        type: 'textField',
        order: 9,
        orderLabel: '10.',
        required: true,
        text: "What is the next step in the project?"
    },
    workingInGroup: {
        key: 'workingInGroup',
        type: 'radioGroup',
        order: 10,
        orderLabel: '11.',
        required: true,
        text: "Are you developing this idea alone or together with others?",
        options: [
            { value: "alone", label: "Alone" },
            { value: "group", label: "Group" },
        ],
    },
}