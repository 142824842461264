import { TableCell } from "@mui/material"
import { useMemo } from "react"
import { Link } from "react-router-dom"

export const TableRowIBOName = ({ project }) => {
    const projName = useMemo(() => {
        if (project?.companyData?.compName != null && project?.companyData?.compName !== "") {
            if (project?.existingComp === 'no' || project?.existingComp === 'No/Not yet') {
                return project?.value;
            }
            else {
                return project?.companyData?.compName;
            }

        }
        else {
            return project?.value;
        }
    }, [project]);

    return (
        <TableCell padding='normal' sx={{ maxWidth: '340px' }}>
            <Link
                to='/matrix'
                state={{ projectData: project }}
                style={{ color: '#4eacc1', marginLeft: '-5px' }}
            >
                <strong>{projName}</strong>
            </Link>
        </TableCell>
    )
}