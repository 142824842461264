import { Autocomplete, Box, Button, DialogContent, Divider, FormControl, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../helper/bootstrapDialog";
import PendingIcon from '@mui/icons-material/Pending';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { generateTimeslots } from "../../../../helper/timeslotGenerator";
import dayjs from "dayjs";
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { confirmBookedFM, rejectBookedFM } from "../../../../firebase/cloudFunctions";
import { AlertBox } from "../../../../helper/AlertBox";

export const ConfirmFMBooking = ({ projData, bdowners }) => {
    console.log("PRoj: ", projData)

    const preSelectedBD = () => {
        if (projData?.BDOwner != null && Array.isArray(projData?.BDOwner) === true) {
            if (projData?.BDOwner[0] != null && projData?.BDOwner[0] !== "") {
                return String(projData?.BDOwner[0]);
            }
        }

        return '';
    }


    const [loading, setLoading] = useState(false);
    const [selectedBD, setSelectedBD] = useState(preSelectedBD());

    const [openInfo, setOpenInfo] = useState(false);

    const [error, setError] = useState(false);
    const [infoMsg, setInfoMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const [selectedTime, setSelectedTime] = useState('');
    const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
    const [selectedTimeDate, setSelectedTimeDate] = useState("");

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleTimeChange = (newValue) => {
        //console.log(new Date(selectedDate).toDateString() + " " + new Date(newValue.time).toTimeString());
        //console.log("Time: ", newValue)
        if (newValue != null) {
            // @ts-ignore
            setSelectedTime(newValue);
            setSelectedTimeDate(selectedDate.toDate().toDateString() + " " + newValue);
        }
        else {
            setSelectedTime(null)
            setSelectedTimeDate(selectedDate.toDate().toDateString());
        }
    };

    const handleDateChange = (newValue) => {
        //console.log(new Date(newValue).toDateString() + " " + new Date(selectedTime).toTimeString());
        if (newValue != null) {
            setSelectedDate(newValue)
            setSelectedTimeDate(new Date(newValue).toDateString() + " " + selectedTime);
        }
        else {
            setSelectedDate(null)
            setSelectedTimeDate(new Date().toDateString() + " " + selectedTime);
        }
    };

    const confirmFM = () => {
        setInfoMsg('');
        setErrorMsg('');
        setErrorInfo('');
        setError(false);

        setLoading(true);
        confirmBookedFM({
            projectID: projData.id,
            assignedBD: selectedBD,
            bookedTime: new Date(selectedTimeDate),
        }).then(() => {
            setInfoMsg("First meet confirmed!");
            setErrorInfo('');
            setError(false);
            handleClose();
        }).catch((error) => {
            console.error("Error: ", error);
            setErrorMsg("Could not confirm FM!");
            setErrorInfo(error);
            setError(true);
        }).finally(() => {
            setOpenInfo(true);
            setLoading(false);
        });
    }

    const rejectFM = () => {
        setInfoMsg('');
        setErrorMsg('');
        setErrorInfo('');
        setError(false);

        setLoading(true);
        rejectBookedFM({
            projectID: projData.id,
        }).then(() => {
            setInfoMsg("First meet rejected!");
            setErrorInfo('');
            setError(false);
            handleClose();
        }).catch((error) => {
            console.error("Error: ", error);
            setErrorMsg("Something went wrong when rejecting FM!");
            setErrorInfo(error);
            setError(true);
        }).finally(() => {
            setOpenInfo(true);
            setLoading(false);
        });
    }


    return (
        <>
            <IconButton sx={{ height: '22px', width: '22px', marginRight: '-2px' }} size='small' onClick={handleClickOpen}>
                <PendingIcon htmlColor='#efb236' />
            </IconButton>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <h6 style={{ padding: '10px 10px 0px 10px' }}><strong>Confirm first meet</strong></h6>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent>
                    <Stack alignItems={'center'}>
                        <Box sx={{ padding: '2px 15px' }}>
                            <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Assign BD</InputLabel>
                                    <Select
                                        id="select-bd"
                                        label={"Assign BD"}
                                        value={selectedBD}
                                        size="small"
                                        sx={{ minWidth: '350px' }}
                                        variant="outlined"
                                        onChange={(event) => setSelectedBD(event.target.value)}>

                                        <MenuItem value={''}></MenuItem>
                                        {bdowners.map((owner) => {
                                            if (owner?.BDSelectable !== false) {
                                                return (
                                                    <MenuItem
                                                        key={owner.id}
                                                        value={owner.id}
                                                    >
                                                        {owner.value}
                                                    </MenuItem>
                                                );
                                            }
                                        })}

                                    </Select>
                                </FormControl>

                            </Stack>
                        </Box>

                        <Box sx={{ padding: '15px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Typography sx={{ paddingBottom: '15px', fontSize: '14px !important' }}>
                                    Meeting time:
                                </Typography>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <DatePicker
                                        label="Date"
                                        value={selectedDate}
                                        format={'ddd YYYY-MM-DD'}
                                        onChange={handleDateChange}
                                        slotProps={{ textField: { size: 'small', sx: { width: 200 } } }}
                                    />

                                    <Autocomplete
                                        freeSolo
                                        size="small"
                                        disableClearable
                                        value={selectedTime}
                                        onChange={(event, newValue) => handleTimeChange(newValue)}
                                        options={generateTimeslots().map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Time"
                                                sx={{ width: 140 }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: <InputAdornment position="end"><IconButton size="small"><AccessTimeRoundedIcon /></IconButton></InputAdornment>,
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Box>

                        <Box height={'8px'} width={'85%'}>
                            {loading ? (
                                <LinearProgress />
                            ) : ("")}
                        </Box>
                        <Stack direction={'row'} spacing={4} justifyContent={'space-around'}>
                            <Button onClick={confirmFM} disabled={selectedBD == null || selectedBD === "" || selectedTimeDate == null || selectedTimeDate === "" || loading === true} sx={{ width: '150px' }} variant="contained">
                                Confirm
                            </Button>

                            <Button onClick={rejectFM} disabled={loading === true} sx={{ width: '150px' }} variant="contained">
                                Reject
                            </Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </BootstrapDialog>

            <AlertBox open={openInfo} setOpen={setOpenInfo} isError={error} successMsg={infoMsg} errorMsg={errorMsg} errorInfo={errorInfo} />
        </>
    );
}