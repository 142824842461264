import { useLocation, useNavigate } from "react-router-dom";
import { StepperTabComponentScale } from "../StepperTabs/StepperTabComponentScale"
import { useEffect, useState } from "react";
import { StyledTabPanel } from "../../../helper/StyledTabPanel";
import HomeIbo from "../../HomeIBO/HomeIbo";
import { EntryStep } from "../StepperComponents/EntryStep";
import { MeetStep } from "../StepperComponents/MeetStep";
import { ExitStep } from "../StepperComponents/ExitStep";
import { StepperTabComponentIncubator } from "../StepperTabs/StepperTabComponentIncubator";
import { Box } from "@mui/material";
import { BDInfoBox } from "../../HomeIBO/components/BDInfoBox";
import { useAuth } from "../../../contexts/AuthContext";


export const IncubatorStepperContent = ({ project, isPopup = false }) => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation()
    const selectedTab = location.hash;
    const setInitTab = (selectedTab) => {
        if (isPopup) {
            return 1;
        }
        else {
            if (selectedTab != null && selectedTab !== "") {
                let local = selectedTab.replace('#step', '');
                const index = Number(local);

                if (isNaN(index) === false) {
                    return index;
                }
            }
            else {
                return 0;
            }
        }
    }

    const [currentTab, setCurrentTab] = useState(setInitTab(selectedTab));

    useEffect(() => {
        if (isPopup === false) {
            getHashValue(selectedTab);
        }

    }, [selectedTab])

    /**
     * 
     * @param {string} hashVal 
     */
    const getHashValue = (hashVal) => {
        let local = hashVal.replace('#step', '');
        const index = Number(local);

        if (isNaN(index) === false && index !== currentTab) {
            setCurrentTab(index);
        }
    }

    /**
     * 
     * @param {string} href 
     */
    const handleSetTabValue = (href) => {
        let local = href.replace('#step', '');
        const index = Number(local);

        if (isNaN(index) === false && index !== currentTab) {
            setCurrentTab(index);
        }
    }

    /**
     * 
     * @param {String} href 
     */
    const onClickStep = (href) => {
        if (isPopup) {
            if (href.startsWith('#')) {
                handleSetTabValue(href);
            }
            else {
                navigate(href, { state: { projectData: project } })
            }
        }
        else {
            if (href.startsWith('#')) {
                navigate(window.location.pathname + href, { state: { projectData: project } })
            }
            else {
                navigate(href, { state: { projectData: project } })
            }
        }
    }



    return (
        <>
            <StepperTabComponentIncubator projData={project} value={currentTab} isPopup={isPopup} onClickStep={onClickStep} />

            {currentUser?.role >= 2 && isPopup === false ? (
                <Box className='bd-info-box'>
                    <BDInfoBox projectData={project} />
                </Box>
            ) : ("")}

            <StyledTabPanel value={currentTab} index={0}>
                <HomeIbo projectData={project} track={'start'} isPopup={isPopup} />
            </StyledTabPanel>
            <StyledTabPanel value={currentTab} index={1}>
                <EntryStep projectData={project} />
            </StyledTabPanel>
            <StyledTabPanel value={currentTab} index={2}>
                <MeetStep projectData={project} step="meet1" location="First Meet" />
            </StyledTabPanel>
            <StyledTabPanel value={currentTab} index={3}>
                <ExitStep projectData={project} step="f" />
            </StyledTabPanel>
        </>
    )
}