import { Alert, MenuItem, Select, Snackbar, TableCell } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";
import { assignBDToProj } from "../../../../firebase/cloudFunctions";


export const TableRowBD = ({ project, bdOwners }) => {
    const confirm = useConfirm();

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };

    function setDefaultOwner(project) {
        if (project?.BDOwner == null || project?.BDOwner.length === 0) {
            return -1
        } else {
            return project?.BDOwner[0]
        }
    }

    function addUnassigned(project) {
        if (project?.BDOwner == null || project?.BDOwner.length === 0) {
            return (
                <MenuItem value={-1} disabled>
                    Unassigned
                </MenuItem>
            );

        } else {
            return;
        }
    }

    function AssignBD(projectID, event) {
        let userid = event.target.value

        let username;
        bdOwners.forEach((owner) => {
            if (event.target.value === owner.id) {
                username = owner.value;
            }
        })

        confirm({ title: 'Please confirm', description: 'Are you sure you want to assign ' + username + ' as Business Designer?' })
            .then(() => {
                setIsLoading(true);
                assignBDToProj({
                    projectID: projectID,
                    userID: userid,
                }).then(() => {
                    setIsLoading(false);
                    setOpenSnackbar(true);
                }).catch(() => {
                    setError(true);
                    setIsLoading(false);
                    setOpenSnackbar(true);
                });
            })
            .catch(() => { setIsLoading(false); });
    }

    function renderAssignedBD(value){
        if (value != null && value !== -1){
            let assigned = bdOwners.find((item) => item.id === value);

            if (assigned != null){
                return assigned.value;
            }
            else{
                return '-';
            }
        }
        else{
            return 'Unassigned';
        }
    }

    return (
        <>
            <TableCell padding='none'>
                <Select
                    id="select-bd"
                    value={setDefaultOwner(project)}
                    size="small"
                    sx={{ fontSize: '11px' }}
                    variant="standard"
                    disabled={isLoading}
                    onChange={(event) => AssignBD(project.id, event)}
                    renderValue={(v) => renderAssignedBD(v)}
                    >
                    {addUnassigned(project)}

                    {bdOwners.map((owner) => {
                        if (owner?.BDSelectable !== false) {
                            return (
                                <MenuItem
                                    key={owner.id}
                                    value={owner.id}
                                >
                                    {owner.value}
                                </MenuItem>
                            );
                        }
                    })}

                </Select>
            </TableCell>

            {error ? (
                <Snackbar
                    open={openSnackbar}
                    onClose={closeSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={closeSnackbar} severity="error">
                        Something went wrong!
                    </Alert>
                </Snackbar>
            ) : (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={2000}
                    onClose={closeSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="success">Done!!</Alert>
                </Snackbar>
            )}

        </>

    )
}