import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, LinearProgress, Stack, TextField, Tooltip, Typography } from "@mui/material"
import useGetUserProject from "../../hooks/userData/useGetUserProject"
import { useEffect, useState } from "react";
import { useGetOwnProjectEntryQuestions } from "../../hooks/projectData/useGetProjectEntryQuestions";
import { QuarterReportInfoCompInfo } from "./QuarterReviewCompInfo";
import { addProjectInfo, confirmQuarterData, entryStepQuestions_save } from "../../firebase/cloudFunctions";
import { CompanyChoices, TrackLabels } from "../../constants/labels/labels";
import { QuarterReviewTeamsinfo } from "./QuarterReviewTeamsinfo";
import { useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";

export const QuarterReportStep = () => {
    const navigate = useNavigate();
    const analytics = getAnalytics();

    const { project, loading: loadingProject } = useGetUserProject();
    const { questionList, isLoading: loadingEntryQuestions } = useGetOwnProjectEntryQuestions();

    const [loading, setLoading] = useState(false);
    const [projValues, setProjValues] = useState(Object);
    const [ideasValues, setIdeasValues] = useState(Object);

    const [statusUpdate, setStatusUpdate] = useState('');
    const [statusUpdateInvalid, setStatusUpdateInvalid] = useState(false);

    const [confirmInfo, setConfirmInfo] = useState(false);
    const [projDataInvalid, setProjDataInvalid] = useState(false);
    const [teamsDataInvalid, setTeamsDataInvalid] = useState(false);

    const [saveTriggerComp, setSaveTriggerComp] = useState('');
    const [saveTriggerTeams, setSaveTriggerTeams] = useState('');
    const [update, setUpdate] = useState(false);

    const [autoSave, setAutoSave] = useState('');

    useEffect(() => {
        if (loadingProject === false) {
            if (project?.data?.activeTrack !== TrackLabels.incubator.key && project?.data?.activeTrack !== TrackLabels.scale.key) {
                navigate('/home', { replace: true });
            }
            loadProjectData(project);
        }
        if (loadingEntryQuestions === false) {
            loadIdeasData(questionList);
        }
    }, [loadingProject, loadingEntryQuestions]);

    useEffect(() => {
        if (saveTriggerComp !== '') {
            const delayDebounceFn = setTimeout(() => {
                updateProjectInfo();
                setSaveTriggerComp('');
            }, 3000)

            return () => {
                clearTimeout(delayDebounceFn);
            }
        }
    }, [saveTriggerComp]);

    useEffect(() => {
        if (saveTriggerTeams !== '') {
            const delayDebounceFn = setTimeout(() => {
                updateIdeasProgress();
                setSaveTriggerTeams('');
            }, 3000)

            return () => {
                clearTimeout(delayDebounceFn);
            }
        }
    }, [saveTriggerTeams]);

    useEffect(() => {
        if (confirmInfo === true) {
            validateUpdate();
        }
    }, [confirmInfo])

    const isFirstReview = () => {
        if (project?.data?.trackParticipation != null && project?.data?.trackParticipation[project?.data?.activeTrack] != null) {
            if (project?.data?.trackParticipation[project?.data?.activeTrack] == null || Number(project?.data?.trackParticipation[project?.data?.activeTrack]) === 0) {
                return true;
            }
        }

        return false;
    }

    const handleChangeProjData = (e) => {
        const { name, value } = e.target;
        const localForms = projValues;

        if (name === "region") {
            //console.log("Region: ", value?.label)
            if (localForms["county"] != null && localForms["county"] !== "") {
                localForms["county"] = "";
            }
            localForms['compCity'] = ";" + value?.label;
        }
        else if (name === "county") {
            //console.log("County: ", value?.label)
            if (localForms["region"] != null && localForms["region"] !== "") {
                localForms['compCity'] = value?.label + ";" + localForms["region"].label;
            }
            else {
                localForms['compCity'] = value?.label + ";";
            }
        }
        else if (name === "country") {
            //console.log("County: ", value?.label)
            if (localForms["region"] != null && localForms["region"] !== "") {
                localForms['compCity'] = value?.label + ";" + localForms["region"].label;
            }
            else {
                localForms['compCity'] = value?.label + ";";
            }
        }
        localForms[name] = value;

        setProjValues(localForms);
        setConfirmInfo(false);
    };

    const handleChangeIdeas = (e) => {
        const { name, value } = e.target;
        const localForms = ideasValues;
        // Set values
        if (name === "globalGoals") {
            let index = localForms.globalGoalsList.indexOf(value);
            if (index !== -1) {
                localForms.globalGoalsList.splice(index, 1);
            }
            else {
                localForms.globalGoalsList.push(value);
            }
            localForms.globalGoals = localForms.globalGoalsList.join(';');
        }
        else {
            localForms[name] = value;
        }

        setIdeasValues(localForms);
        setConfirmInfo(false);
    };

    const loadProjectData = (projData) => {
        let dbValues = {
            founded: "",
            projName: "",
            compName: "",
            website: "",
            revenue: "",
            compCity: "",
            orgNum: "",
            compRole: "",
            compForm: "",
            existingComp: "",
        };

        console.log("ProjData: ", projData)

        if (projData.data?.projectName != null) {
            dbValues['projName'] = projData.data?.projectName;
        }

        if (projData.data?.website != null) {
            dbValues['website'] = projData.data?.website;
        }

        if (projData.data?.existingComp != null) {
            console.log("Here.", projData.data?.existingComp)
            dbValues['existingComp'] = projData.data?.existingComp;
        }

        if (projData.compData?.founded != null) {
            dbValues['founded'] = projData.compData?.founded;
        }

        if (projData.compData?.compCity != null) {
            dbValues['compCity'] = projData.compData?.compCity;
            if (String(projData.compData?.compCity).includes(';') === true) {
                let cityData = String(projData.compData?.compCity).split(';');
                if (cityData[1] === "Outside Sweden") {
                    if (cityData[0] != null && cityData[0] !== "") {
                        dbValues['country'] = { label: cityData[0] };
                    }
                }
                else {
                    if (cityData[0] != null && cityData[0] !== "") {
                        dbValues['county'] = { label: cityData[0] };
                    }
                }

                if (cityData[1] != null && cityData[1] !== "") {
                    dbValues['region'] = { label: cityData[1] };
                }
            }
        }

        if (projData.compData?.compForm != null) {
            dbValues['compForm'] = projData.compData?.compForm;
        }

        if (projData.compData?.compOwnership != null) {
            dbValues['compOwnership'] = projData.compData?.compOwnership;
        }

        if (projData.compData?.compName != null) {
            dbValues['compName'] = projData.compData?.compName;
        }

        if (projData.compData?.orgNum != null) {
            dbValues['orgNum'] = projData.compData?.orgNum;
        }

        if (projData.compData?.compRole != null) {
            dbValues['compRole'] = projData.compData?.compRole;
        }

        if (projData.compData?.revenue != null) {
            dbValues['revenue'] = projData.compData?.revenue;
        }

        if (projData.compData?.numEmployees != null) {
            dbValues['numEmployees'] = projData.compData?.numEmployees;
        }

        setProjValues(dbValues);
        setUpdate(!update);
    }

    const loadIdeasData = (questionList) => {
        let dbValues = {
            globalGoalsList: [],
            ideas: "",
            unique: "",
            already: "",
            competitors: "",
            customers: "",
            demand: "",
            challenge: "",
            aloneORteam: "",
            competences: "",
            timeANDresources: "",
            motivate: "",
            globalGoals: "",
            nextStep: "",
        };

        questionList.forEach(element => {
            if (element?.id === "globalGoals") {
                const goalList = String(element?.answerData?.answer).split(';');
                dbValues['globalGoalsList'] = goalList;
            }

            dbValues[element?.id] = element?.answerData?.answer;
        });

        setIdeasValues(dbValues);
    }

    const updateProjectInfo = () => {
        setLoading(true);
        const data = {
            projectID: project.id,
            existingComp: projValues.existingComp,
            updateData: true,
        }

        if (projValues.existingComp === CompanyChoices['yes_new'] || projValues.existingComp === CompanyChoices['yes_established']) {
            data['compForm'] = projValues.compForm
            data['orgNum'] = projValues.orgNum
            data['compName'] = projValues.compName
            data['compCity'] = projValues.compCity


            if (projValues.existingComp === CompanyChoices['yes_established']) {
                data['founded'] = projValues.founded;
                data['compRole'] = projValues.compRole;
                data['revenue'] = projValues.revenue;
                data['compOwnership'] = projValues.compOwnership
                data['numEmployees'] = projValues.numEmployees
            }
        }

        console.log("Send: ", data)
        addProjectInfo(data).then(() => {
            setAutoSave(new Date(Date.now()).toLocaleString())
        }).catch((error) => {
            console.error("Error:", error)
        }).finally(() => {
            setLoading(false);
        });
    }

    const collectProjData = () => {
        const data = {
            existingComp: projValues.existingComp,
        }

        if (projValues.existingComp === CompanyChoices.yes_new || projValues.existingComp === CompanyChoices.yes_established) {
            data['compForm'] = projValues.compForm
            data['orgNum'] = projValues.orgNum
            data['compName'] = projValues.compName
            data['compCity'] = projValues.compCity


            if (projValues.existingComp === CompanyChoices.yes_established) {
                data['founded'] = projValues.founded;
                data['compRole'] = projValues.compRole;
                data['revenue'] = projValues.revenue;
                data['compOwnership'] = projValues.compOwnership
                data['numEmployees'] = projValues.numEmployees
            }
        }

        return data;
    }

    function collectDataSaveProgress() {
        const dataToSend = [];

        if (ideasValues.challenge != null && ideasValues.challenge !== "") {
            dataToSend.push({ title: "challenge", answer: ideasValues.challenge })
        }
        if (ideasValues.aloneORteam != null && ideasValues.aloneORteam !== "") {
            dataToSend.push({ title: "aloneORteam", answer: ideasValues.aloneORteam })
        }
        if (ideasValues.competences != null && ideasValues.competences !== "") {
            dataToSend.push({ title: "competences", answer: ideasValues.competences })
        }
        if (ideasValues.timeANDresources != null && ideasValues.timeANDresources !== "") {
            dataToSend.push({ title: "timeANDresources", answer: ideasValues.timeANDresources })
        }
        if (ideasValues.nextStep != null && ideasValues.nextStep !== "") {
            dataToSend.push({ title: "nextStep", answer: ideasValues.nextStep })
        }

        return dataToSend;
    }

    const updateIdeasProgress = () => {
        if (collectDataSaveProgress().length > 0) {
            setLoading(true);
            // call the cloud function. 

            entryStepQuestions_save({
                projectID: project.id,
                entryQuestions: collectDataSaveProgress(),
            }).then(() => {
                setAutoSave(new Date(Date.now()).toLocaleString())
            }).catch((error) => {
                console.error("Error", error)
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const saveConfirmData = () => {
        setLoading(true);
        let projData = collectProjData();
        let teamsData = collectDataSaveProgress();

        console.log("Save data: ", project.id, confirmInfo, projData, teamsData)

        confirmQuarterData({
            projectID: project.id,
            confirmData: confirmInfo,
            projData: projData,
            teamsData: teamsData,
        }).then(() => {
            logEvent(analytics, 'completed_confirm_quarter_review', {
                projectID: project.id,
            });
            navigate('/home', { replace: true });
        }).catch((error) => {
            console.error("Error", error)
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleUpdateStatus = (event) => {
        setStatusUpdate(event.target.value);
        setStatusUpdateInvalid(false);
    }

    const validateUpdate = () => {
        if (isFirstReview()) {
            setStatusUpdateInvalid(false);
        }
        else {
            if (statusUpdate != null && statusUpdate !== "") {
                setStatusUpdateInvalid(false);
            }
            else {
                setStatusUpdateInvalid(true);
            }
        }
    }

    return (
        <Box>
            {isFirstReview() ? (
                <Box style={{ margin: "30px 0 50px" }}>
                    <Typography variant="h4" align="center">
                        Supplementary information
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        align="center"
                        style={{ margin: "10px 0" }}
                    >
                        Please read through your previous answers and complement the signup with the additional information that is neccecary in order to begin Krinova Business Platform {TrackLabels[project?.data?.activeTrack]?.label}.
                    </Typography>
                </Box>
            ) : (
                <Box style={{ margin: "30px 0 50px" }}>
                    <Typography variant="h4" align="center">
                        Quarterly review
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        align="center"
                        style={{ margin: "10px 0" }}
                    >
                        Please read through your previous answers and update any information that is missing or not up to date.
                    </Typography>
                </Box>
            )}


            <QuarterReportInfoCompInfo handleChange={handleChangeProjData} values={projValues} setSaveTrigger={setSaveTriggerComp} setInvalidData={setProjDataInvalid} confirmInfo={confirmInfo} />
            <QuarterReviewTeamsinfo handleChange={handleChangeIdeas} values={ideasValues} setSaveTrigger={setSaveTriggerTeams} setInvalidData={setTeamsDataInvalid} confirmInfo={confirmInfo} />
            {isFirstReview() === false ? (
                <div style={{ padding: '10px' }}>
                    <Grid container spacing={3} justifyContent="center" padding={3}>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControl sx={{ width: '100%' }} required>
                                <FormLabel sx={{ height: '55px' }}>Write a brief summary of the main activities or events relevant to the development ofyour idea or business since your last update.</FormLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    name={'statusUpdate'}
                                    rows={4}
                                    onChange={handleUpdateStatus}
                                    value={statusUpdate || ""}
                                    onBlur={() => { validateUpdate() }}
                                    error={statusUpdateInvalid}
                                    helperText={statusUpdateInvalid && "Answer is required"}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            ) : ("")}



            <Stack alignItems={'center'} sx={{ paddingBottom: '30px' }}>
                <FormGroup>
                    <FormControlLabel required control={<Checkbox checked={confirmInfo} onChange={() => setConfirmInfo(!confirmInfo)} />} label="I hereby confirm that the information above is valid and up to date" />
                </FormGroup>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {loading ? (
                        <Box sx={{ maxWidth: '310px', minWidth: '260px' }}>
                            <LinearProgress color='info' />
                        </Box>
                    ) : (
                        <Box sx={{ marginBottom: "4px" }}>

                        </Box>
                    )}
                </div>
                <Tooltip arrow title={confirmInfo === true && (projDataInvalid || teamsDataInvalid || statusUpdateInvalid) ? "Please fill in all required answers before submitting" : ""}>
                    <span>
                        <Button onClick={saveConfirmData} disabled={confirmInfo === false || projDataInvalid || teamsDataInvalid || statusUpdateInvalid} variant="contained">
                            Submit
                        </Button>
                    </span>
                </Tooltip>

                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px', color: '#28a745' }}>
                    <i>{autoSave !== '' ? ('Last auto-save: ' + autoSave) : ('')}</i>
                </div>
            </Stack>
        </Box>
    )
}