
import { useEffect, useMemo, useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

import { Alert, Box, CircularProgress, Snackbar, Table, TableBody, TableFooter, TablePagination, TableRow } from '@mui/material';
import { OrderByLabels, ProcessStepIndex, TrackLabels } from '../../../constants/labels/labels';

import { BDTableHeader } from './TableHeader/TableHeaderRow';
import { StepTableRow } from './TableRow';

import { useGetBdOwners } from '../../../hooks/userData/useGetBdOwners';
import { useGetProjects } from '../../../hooks/projectData/useGetProjects';
import { useGetIboNames } from '../../../hooks/userData/useGetIboNames';
import { useGetProjectSettingsData } from '../../../hooks/settings/useGetProjectSettingsData';

import { assignBDToProj } from '../../../firebase/cloudFunctions';

import '../../../assets/scss/app.scss'

const TableIbo = () => {
    const confirm = useConfirm();
    const location = useLocation();
    const selectedTab = location.pathname;
    //console.log("Selected:", selectedTab)
    const { loading: loadingSettings, projectSettings } = useGetProjectSettingsData();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [searchValString, setSearchValString] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const { currentUser } = useAuth();
    const projects = useGetProjects();
    const bdowners = useGetBdOwners();
    const [iboNames] = useGetIboNames();
    const [error, setError] = useState(false);
    const [orderBy, setOrderBy] = useState("Next");
    const [orderByDir, setOrderByDir] = useState("desc");
    const [filterTrackNames, setFilterTrackNames] = useState([]);
    //const [sortableProjects, setSortableProjects] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [bdFilter, setBdFilter] = useState('');
    const [openFilter, setOpenFilter] = useState(false);
    const [filterStatus, setFilterStatus] = useState('active')
    const [updateSearch, setUpdateSearch] = useState(false);

    useEffect(() => {
        if (currentUser?.userInfo?.bdTableSettings != null) {
            const bdSettings = currentUser?.userInfo?.bdTableSettings;
            if (bdSettings.activeFilter != null) {
                setFilterStatus(bdSettings.activeFilter);
            }

            if (bdSettings.bdFilter != null) {
                setBdFilter(bdSettings.bdFilter);
            }

            if (bdSettings.orderBy != null) {
                setOrderBy(bdSettings.orderBy);
            }

            if (bdSettings.orderByDir != null) {
                setOrderByDir(bdSettings.orderByDir);
            }

            if (bdSettings.selectedTracks != null) {
                let localSelectedTrackVals = bdSettings.selectedTracks.split(';');
                setFilterTrackNames(localSelectedTrackVals);
            }
        }
    }, [currentUser]);

    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };

    const sortableProjects = useMemo(() => {
        if (projects.length > 0 && iboNames.length > 0 && bdowners.length > 0) {
            const sortProjects = [];
            //console.log("Trigger", projects.length, iboNames.length, bdowners.length)
            projects.forEach((project) => {
                //console.log("Projekt: ", project)
                let IboName = "Not Found"
                let BdName = "Unassigned"
                let bdID;
                const index = iboNames.find(iboName => iboName.id === project.IBOOwner[0])
                if (index != null) {
                    IboName = index.value;
                }
                const bdindex = bdowners.find(bdName => bdName.id === project.BDOwner[0])

                if (bdindex != null) {
                    BdName = bdindex.value;
                    bdID = bdindex.id;
                }

                let projName = project.value;

                if (project?.companyData?.compName != null && project?.companyData?.compName !== "") {
                    projName = project?.companyData?.compName;
                }

                if (project?.nextDeadline != null) {
                    sortProjects.push({
                        project: project,
                        id: project.id,
                        IBO: projName,
                        Contact: IboName,
                        BD: BdName,
                        BDId: bdID,
                        isActive: project.isActive,
                        Progress: project.currentStep,
                        activeTrack: project.activeTrack,
                        trackParticipation: project.trackParticipation,
                        alreadyBookedFM: project.alreadyBookedFM,
                        isDev: project.isDev,
                        Next: new Date(project.nextDeadline.time * 1000),
                    });
                }
                else {
                    sortProjects.push({
                        project: project,
                        id: project.id,
                        IBO: projName,
                        Contact: IboName,
                        BD: BdName,
                        BDId: bdID,
                        isActive: project.isActive,
                        Progress: project.currentStep,
                        activeTrack: project.activeTrack,
                        trackParticipation: project.trackParticipation,
                        alreadyBookedFM: project.alreadyBookedFM,
                        isDev: project.isDev,
                        Next: new Date(8640000000000000),
                    });
                }

            });


            return sortProjects;
        }
        else {
            return [];
        }
    }, [projects, iboNames, bdowners]);

    function AssignBD(projectID, event) {
        let userid = event.target.value
        //console.log('this event' + event.target.value);
        let username;
        bdowners.forEach((owner) => {
            if (event.target.value === owner.id) {
                username = owner.value;
            }
        })
        confirm({ title: 'Please confirm', description: 'Are you sure you want to assign ' + username + ' as Business Designer?' })
            .then(() => {
                setIsLoading(true);
                assignBDToProj({
                    projectID: projectID,
                    userID: userid,
                })
                    .then(() => {
                        //set `isLoading` state to false, so the spinner is not rendered anymore
                        setIsLoading(false);

                        //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                        setOpenSnackbar(true);
                    })
                    //this code below runs when the message was NOT successfully sent from inside of the cloud function
                    .catch(() => {
                        //set `error` state to true
                        setError(true);

                        //set `isLoading` state to false, so the spinner is not rendered anymore
                        setIsLoading(false);

                        //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                        setOpenSnackbar(true);
                    });
            })
            .catch(() => { setIsLoading(false); });
    }

    const createSortHandler = (property) => {
        //onRequestSort(event, property);
        const isAsc = orderBy === property && orderByDir === 'desc';
        setOrderByDir(isAsc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    function descendingComparator(a, b, orderBy) {
        if (orderBy === OrderByLabels.Track) {
            if (a?.activeTrack != null && b?.activeTrack != null) {
                return TrackLabels[b?.activeTrack]?.index - TrackLabels[a?.activeTrack]?.index;
            }
            else if (a?.activeTrack != null) {
                return 1;
            }
            else if (b?.activeTrack != null) {
                return -1;
            }
            else {
                return 0;
            }
        }
        else if (orderBy === OrderByLabels.Progress) {
            if (a?.Progress != null && b?.Progress != null) {
                return ProcessStepIndex[b?.Progress] - ProcessStepIndex[a?.Progress];
            }
            else if (a?.Progress != null) {
                return 1;
            }
            else if (b?.Progress != null) {
                return -1;
            }
            else {
                return 0;
            }
        }
        else if (orderBy === OrderByLabels.Next) {
            if (a?.activeTrack === 'exit' && b?.activeTrack === 'exit') {
                return 0;
            }
            else if (a?.activeTrack === 'exit') {
                return -1;
            }
            else if (b?.activeTrack === 'exit') {
                return 1;
            }
            else {
                if (b[orderBy] < a[orderBy]) {
                    return -1;
                }
                if (b[orderBy] > a[orderBy]) {
                    return 1;
                }
                return 0;
            }

        }
        else {
            if (getTrimmedLowercaseVal(b[orderBy]) < getTrimmedLowercaseVal(a[orderBy])) {
                return -1;
            }
            if (getTrimmedLowercaseVal(b[orderBy]) > getTrimmedLowercaseVal(a[orderBy])) {
                return 1;
            }
            return 0;
        }

    }

    const getTrimmedLowercaseVal = (/** @type {String} */ orgVal) => {
        if (orgVal != null) {
            return orgVal.toLocaleLowerCase().trim();
        }
        else {
            return orgVal;
        }
    }

    function getComparator(order, orderBy) {
        return order === 'asc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const filterTestProj = (item) => { 
        if (item?.isDev === true) {
            if (currentUser?.isDev === true) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }

    function filterTab(item) {
        if (filterTrackNames.length > 0) {
            let found = false;
            for (let index = 0; index < filterTrackNames.length; index++) {
                if (filterTrackNames[index] === 'unassigned') {
                    if (item?.activeTrack == null || item?.activeTrack === "") {
                        found = true;
                    }
                }
                else if (item?.activeTrack === filterTrackNames[index]) {
                    found = true;
                }
            }

            if (found === false) {
                return false;
            }
        }


        if (filterStatus === 'active' && (item.isActive === false || typeof (item.isActive) === 'string')) {
            return false;
        }
        else if (filterStatus === 'activePaused' && (item.isActive === false || (typeof (item.isActive) === 'string' && item.isActive !== "paused"))) {
            return false;
        }
        else if (filterStatus === 'inactive' && (item.isActive === true || item.isActive == null)) {
            return false;
        }
        else if ((bdFilter === "assignedMe" || bdFilter === "") && item.BDId !== currentUser.uid) {
            return false;
        }
        else if (bdFilter === "assignedMeAndUnassigned" && ((item.BDId != null && item.BDId !== "") && item.BDId !== currentUser.uid)) {
            return false;
        }
        else if ((bdFilter === "assigned" || bdFilter === "") && (item.BDId == null || item.BDId === "")) {
            return false;
        }
        else {
            if (searchVal != null && searchVal !== '') {
                if (item?.IBO.toLowerCase().includes(searchVal.toLowerCase())) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return true;
            }
        }

    }

    function filterBDs(item) {
        if (bdFilter === "assignedMe" || bdFilter === "") {
            if (item.BDId === currentUser.uid) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (bdFilter === "assigned") {
            if (item.BDId != null) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }

    const filterSearch = (item) => {
        if (searchVal != null && searchVal !== '') {
            if (item?.IBO.toLowerCase().includes(searchVal.toLowerCase())) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }

    return (
        <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            ) : (
                <Table style={{ marginTop: '20px', }} aria-labelledby="iboTable" aria-label="IBO Table">
                    <BDTableHeader
                        orderBy={orderBy}
                        orderByDir={orderByDir}
                        handleSetOrderBy={createSortHandler}
                        filterStatus={filterStatus}
                        setFilterStatus={setFilterStatus}
                        searchVal={searchVal}
                        setSearchVal={setSearchVal}
                        bdFilter={bdFilter}
                        setBDFilter={setBdFilter}
                        trackFilter={filterTrackNames}
                        setTrackFilter={setFilterTrackNames}
                        isAdmin={selectedTab === "/admin" && currentUser.role >= 3}
                    />
                    <TableBody>

                        {sortableProjects.filter(filterTestProj).filter(filterTab).sort(getComparator(orderByDir, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project, index) => {
                            return (
                                <StepTableRow
                                    key={project.id || index}
                                    project={project.project}
                                    bdowners={bdowners}
                                    iboNames={iboNames}
                                    projectSettings={projectSettings}
                                    isAdmin={selectedTab === "/admin" && currentUser.role >= 3}
                                />
                            )
                        })}

                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelRowsPerPage=""
                                page={page}
                                count={sortableProjects.filter(filterTab).length}
                                rowsPerPageOptions={[10, 20, 50]}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage} />
                        </TableRow>

                    </TableFooter>
                </Table>
            )}
            {/* {projects.map((project) => {
                return (
                    <Comments key={project.id} project={project} />
                )
            })} */}
            {error ? (
                <Snackbar
                    open={openSnackbar}
                    onClose={closeSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={closeSnackbar} severity="error">
                        Something went wrong!
                    </Alert>
                </Snackbar>
            ) : (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={2000}
                    onClose={closeSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="success">Done!!</Alert>
                </Snackbar>
            )}

        </Box>

    )
}

export default TableIbo